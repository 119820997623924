<template>
  <div class="dp-favorite-overview">
    <div class="dp-favorite-overview-section">
      <h2 class="dp-favorite-overview-title">
        お気に入りのレシピを<br />
        登録しましょう。
      </h2>

      <p class="dp-favorite-overview-paragraph">
        お気に入りのレシピがありますか？<br />
        DRIP POD本体に登録すれば<br />
        スマホと接続しなくても、マシンの<br />
        ボタン一つですぐに抽出。
      </p>

      <img
        class="dp-favorite-overview-image"
        src="/assets/img/drip-pod/pic_favorite_overview_example01.png" />

      <div class="dp-favorite-overview-how-to">
        <h3 class="dp-favorite-overview-how-to__title">登録方法</h3>

        <ul class="dp-favorite-overview-list">
          <li>
            レシピの詳細画面、またはアプリからコーヒー抽出完了時、「本体に登録する」ボタンを押してください。
          </li>
          <li>「本体登録に成功しました」と表示されたら登録完了です。</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup: () => {
    return {
      WORDS
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-favorite-overview {
  @include mixin.dpDefaultText;

  padding: 32px 16px;
  background-color: variables.$dpGreyF1;

  * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.dp-favorite-overview-section {
  margin-bottom: 32px;
}

.dp-favorite-overview-paragraph {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

.dp-favorite-overview-image {
  display: block;
  margin: 0 auto 16px;
  width: 100%;
  height: auto;
}

.dp-favorite-overview-title {
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
}

.dp-favorite-overview-how-to {
  padding: 16px 16px 16px 20px;
  background-color: variables.$dpWhite01;

  &__title {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
}

.dp-favorite-overview-list {
  counter-reset: step-list;
  display: grid;
  place-items: start;
  row-gap: 16px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.55;

  > li {
    counter-increment: step-list;
    display: grid;
    place-items: start;
    column-gap: 4px;
    grid-template-columns: auto 1fr;
    margin-bottom: 0;

    &::before {
      display: grid;
      content: counter(step-list);
      place-items: center;
      flex-shrink: 0;
      margin-top: 0.24em;
      margin-left: 2px;
      background-color: variables.$dpBlack01;
      width: 16px;
      height: 16px;
      border-radius: 1em;
      color: variables.$dpWhite01;
      font-size: 10px;
      font-family: Oswald;
      font-weight: 700;
    }
  }
}
</style>
