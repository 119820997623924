var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dp-favorite-overview" }, [
      _c("div", { staticClass: "dp-favorite-overview-section" }, [
        _c("h2", { staticClass: "dp-favorite-overview-title" }, [
          _vm._v(" お気に入りのレシピを"),
          _c("br"),
          _vm._v(" 登録しましょう。 "),
        ]),
        _c("p", { staticClass: "dp-favorite-overview-paragraph" }, [
          _vm._v(" お気に入りのレシピがありますか？"),
          _c("br"),
          _vm._v(" DRIP POD本体に登録すれば"),
          _c("br"),
          _vm._v(" スマホと接続しなくても、マシンの"),
          _c("br"),
          _vm._v(" ボタン一つですぐに抽出。 "),
        ]),
        _c("img", {
          staticClass: "dp-favorite-overview-image",
          attrs: {
            src: "/assets/img/drip-pod/pic_favorite_overview_example01.png",
          },
        }),
        _c("div", { staticClass: "dp-favorite-overview-how-to" }, [
          _c("h3", { staticClass: "dp-favorite-overview-how-to__title" }, [
            _vm._v("登録方法"),
          ]),
          _c("ul", { staticClass: "dp-favorite-overview-list" }, [
            _c("li", [
              _vm._v(
                " レシピの詳細画面、またはアプリからコーヒー抽出完了時、「本体に登録する」ボタンを押してください。 "
              ),
            ]),
            _c("li", [
              _vm._v("「本体登録に成功しました」と表示されたら登録完了です。"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }